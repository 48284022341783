import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Lagerorte from '../../../components/ProductDetails/netstorsys/lagerorte'
import Seo from "../../../components/App/Seo"

const LagerortePage = () => {
  return (
    <Layout>
      <Seo title={"Einfaches managen von Lagerorten und Ladungsträgern."}
           description={"Lagerorte sind das Herz eines jeden WMS. Das einfache Anlegen und das Managen von Lagerorten " +
            "geht in NETSTORSYS besonders leicht von der Hand."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Lagerorte />
      <Footer />
    </Layout>
  );
}

export default LagerortePage
