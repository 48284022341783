import React from "react"
import lagerorteHeader from "../../../assets/images/products/netstorsys/lagerorte_header.svg"
import wmLagerorte from "../../../assets/images/products/netstorsys/wm-lagerorte.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "../../App/FeatureSection"

const Lagerorte = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={lagerorteHeader} alt="about" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETSTORSYS</span>
                <h1 className={"pt-3"}>Lagerorte </h1>
                <p>
                  Lagerorte sind das Herz eines jeden WMS. Das einfache Anlegen und das Managen von Lagerorten geht in
                  NETSTORSYS besonders leicht von der Hand. Die Strukturierung der einzelnen Stellen eines
                  Lagerort-Barcodes ist intuitiv und leicht verständlich. So kann anhand der Lagerortnummer erkannt
                  werden, wo der entsprechende Lagerplatz im Lager seinen Ort hat. Schnelles Suchen und sinnvolle
                  Reports runden das Lagerortmanagement ab.
                </p>

                <WichtigeMerkmale items={[
                  "Vorschlagsbasierte Einlagerung",
                  "Warenprüfung",
                  "Statistiken über Lagerbelegung",
                  "Definierbare Zonen"
                ]} image={<img src={wmLagerorte}
                               alt="Lagerorte Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <p>
                  Eigenschaften eines Lagerorts wie Höhe, Breite, Tiefe, Eigengewicht, Typ (Hochregal etc.), Füllgrad
                  oder weitere können in der Lagerortverwaltung leicht editiert werden. Es ist dabei auch möglich
                  Lagerorttypen anzulegen und bei der Erstellung von Lagerorten auf die Typen zu verweisen. So wird das
                  Management von Lagerorten zu einem einfachen Task. Mittels definierbarer Zonen können ganze Bereiche
                  in einem Lager für andere Verwendungszwecke markiert werden. Damit kann schnell auf unterschiedliche
                  Anforderungen an das Lager reagiert werden.
                </p>

                <h3>Relevantes aus dem Lexikon</h3>
                <LexikonBadgesRandomSection product={"Netstorsys"} />

              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>

      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_medis_framed.png"
                        alt="Bestandsmanagement Screenshot"
                        placeholder="blurred"
                        width={700}
                      />}
                      header={"Bestandsmanagement"}
                      textBlock1={<span>In der Bestandsübersicht kann auf einen Blick der aktuelle Lagerbestand eingesehen werden.
                        Wo ist was eingelagert, liegen die Artikel zusätzlich auf einer Handlingunit, welche Artikel
                        befinden sich aktuell im Picking-Prozess etc. Zusätzlich bietet die Übersicht die Möglichkeit
                        externe Bestände, sofern vorhanden, direkt in NETSTORSYS anzeigen zulassen. Damit haben Sie immer
                        den kompletten Überblick und können fehlerhafte Bestände zwischen den Systemen direkt auswerten.</span>}
      />
    </>
  )
}

export default Lagerorte
